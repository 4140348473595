import './footer.css';


export default function footer(){


    return(
        
        <footer>
         <p class="copyright">© Silent-Freaks 2023</p>
         </footer>
    )}
