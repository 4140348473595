
import React from 'react';
import About from './About.jsx';

function Home() {
  
    return (

        <div  className="Home">

           <About/>

        <div>
      </div>

        </div>

        
        
        
        )}

        export default Home; 
